<template>
  <div class="ab-details ewa-group-term">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>EWA GROUP TERM LIFE $7k</h2>
            <p>
              Members of EWA who are enrolled in a participating plan or plans that offers $7,000 of Term Life coverage will receive only one Term Life benefit per Membership.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="subpage-body box-shadow">
            <div class="row key-benefits-heading">
              <div class="col-md-6">
                <h3>KEY BENEFITS</h3>
                <ul>
                    <li>If you're totally disabled, you may qualify to have your Life premium payments waived for a specific period of time</li>
                    <li>If you have a terminal illness, you can apply for a portion of Life insurance funds early</li>
                    <li>Family may be eligible for a Higher Ed., Child Care or Career Adj. benefit if you die as a result of an accident</li>
                </ul>
              </div>
              <div class="col-md-6">
                    <video width="100%" height="330" controls>
                        <source src="https://corenroll.com/video/Term_Life_Insrance_V4.mp4" type="video/mp4">
                    </video>
              </div>
            </div>
            <div class="row ws-life-insurance">
                <div class="col-12">
                  <h2>What is Life Insurance</h2>
                  <p>A time could come when death or a serious accident keeps you from providing for your family. Group Life insurance can help protect your family’s finances if something happens to you.</p>
                  <p>A death might leave your loved ones facing expenses they couldn’t pay without your income. Besides everyday bills, they could run into extra costs for things like final medical care or a funeral. If an accident left you with a serious injury, you might need to pay for special care and modified living arrangements.</p>
                  <p>How can Life insurance help make things easier for the people you care about?</p>
                  <ul><li><strong>Life insurance</strong> can be used to help protect the people who depend on your income by paying them an amount of money specified in the policy if you die.</li></ul>
                  <p>These benefits can help pay for things like the mortgage, college tuition, child-care costs or daily living expenses. Usually, employers offer these two types of insurance together, but not always.</p>
              </div>
            </div>
            <div class="row benefit-examples">
                <div class="col-12">
                  <img src="../assets/images/Prudential_logo.png" class="memberbenfits-logo">
                  <h4>BENEFIT EXAMPLES</h4>
                  <p>Over half of American households lack enough emergency savings to cover three months of expenses.</p>
              </div>
            </div>
            <div class="row benefit-list">
                <div class="col-12">
               
                                <div class="row b-list">
                      <div class="col-sm-2">Eligibility</div>
                      <div class="col-sm-10">
                              <u>Optional term Life</u> All active members in good standing and who are under age 65
                      </div>
                  </div>
                   <div class="row b-list">
                      <div class="col-sm-2">Effective Date of Coverage</div>
                      <div class="col-sm-10">
                       First day of the month following enrollment
                      </div>
                   </div>
                   <div class="row b-list">
                      <div class="col-sm-2">Guarantee Issue Amounts</div>
                      <div class="col-sm-10">
                      <u>Optional Life:</u> Up to $7,000 Member Only
                      </div>
                   </div>
                    <div class="row b-list">
                      <div class="col-sm-2">Waiver of Premium (Life Plan)</div>
                      <div class="col-sm-10">
                        Does not apply
                      </div>
                   </div>
                  <div class="row b-list">
                      <div class="col-sm-2">Accelerated Benefit Option (Life)</div>
                      <div class="col-sm-10">
                       90% up to $100 Optional Life, Life expectancy 6 Months. Applies to Active Members Only
                      </div>
                   </div>
                   <div class="row b-list">
                      <div class="col-sm-2">Life Exclusions</div>
                      <div class="col-sm-10">
                        2-year suicide exclusion applies
                      </div>
                   </div>
                   <div class="row b-list">
                      <div class="col-sm-2">Exposure and Disappearance Benefit**</div>
                      <div class="col-sm-10">
                       % Of Principal sum consistent with type of loss
                      </div>
                   </div>
                   <div class="row b-list">
                      <div class="col-sm-2">Termination of Coverage</div>
                      <div class="col-sm-10">
                        Coverage terminates:<br>
                        When membership ends
                        Member at age 80
                      </div>
                   </div>
                  <div class="row b-list">
                      <div class="col-sm-2"> Portability</div>
                      <div class="col-sm-10">
                        Does not apply
                      </div>
                   </div> 
                   <div class="row b-list">
                      <div class="col-sm-2">Life Conversion</div>
                      <div class="col-sm-10"><b>Optional Term Life: </b> Applies (member and dependent).</div>
                  </div>
                  <div class="row b-list">
                      <div class="col-sm-2">Effective Date of Benefit reductions, Termination, Changes in Age Band rates due to Member or Dependent Aging</div>
                      <div class="col-sm-10">
                       <u>Member Only </u>
                       <ul>
                         <li> Age 65 – 65% of current coverage amount</li>
                         <li>Age 70 – 50% of coverage amount in force at age 64</li>
                         <li> Age 75 – 25% of coverage amount in force at age 64</li>
                         <li> Coverage terminates at age 80</li>
                       </ul>
                      </div>
                   </div>
              </div>
            </div>
            <span class="line-top-wrap"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EwaGroup",
};
</script>
<style>
.memberbenfits-logo{
  margin-bottom: 1rem;
}
</style>

