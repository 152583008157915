<template>
  <div class="ab-details rd-cmbs-wrap">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>Consumer Medical Bill Solutions</h2>
            <p>(CMBS) - Medical Bill Negotiation</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="subpage-body box-shadow">
            <div class="row">
              <div class="col-12">
                <div class="ab-details-heading">
                  <div class="row">
                    <div class="col-md-8">
                      <h1>
                        The Fastest, Easiest Way to
                        <span class="p-color"
                          >Lower <br />Your Medical Bills.</span
                        >
                      </h1>
                      <p>
                        Consumer Medical Bill Solutions will negotiate your out
                        of network or non-covered medical bills, achieving
                        savings of
                        <strong class="p-color">25%-45%</strong> of the total
                        charges billed.
                      </p>
                    </div>
                    <div class="col-md-4">
                      <img
                        src="../assets/images/cmbs-img.png"
                        alt="Lower your meidcal bills"
                      />
                    </div>
                  </div>
                </div>
                <div class="ab-details-body">
                    <div class="paragraph">
                        <p>
                        All you need to do is register on our website, upload your
                        medical bill and execute a HIPAA release form allowing us to
                        speak to your provider about your medical bill. All of your
                        data is protected following industry standard security
                        measures.
                        </p>
                        <p>
                            Our negotiators will aggressively negotiate a discount on
                            the patient account balance and eliminate the risk of any
                            balance billing from your provider or additional collection
                            efforts. With over 12 years of experience negotiating
                            medical bills on behalf of Health Plans, Third Party
                            Administrators, large corporations and unions, CMBS can
                            typically achieve savings anywhere from 25-45% off your
                            medical bill.
                        </p>
                    </div>
                  
                    <div class="settlement-p">
                        <p><strong><em>Once we have obtained a settlement, we will contact you for approval and scheduled payment for provider.</em></strong></p>
                        <p><strong><em>Our fee is 20% of the amount we save you off of the total patient responsibility. The whole process typically takes 3-7 business days.</em></strong></p>
                    </div>

                    <div class="row save--money">
                        <div class="col-12 text-center">
                            <h3>Save Money in <span class="p-color">3 Easy Steps</span></h3>
                        </div>
                        <div class="col-md-4">
                            <div class="save-money-card">
                                <span class="number">1</span>
                                <h4>Get Started</h4>
                                <p>Fill out our Contact form and provide us your contact information. A member of the Customer Service Team at CMBS will be in touch with you to begin the process.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="save-money-card">
                                <span class="number">2</span>
                                <h4>Evaluation of Medical Bill</h4>
                                <p>Complete and sign a HIPAA release form and send us a copy of the most recent bill along with any additional documentation received from the health plan regarding the medical care.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="save-money-card">
                                <span class="number">3</span>
                                <h4>Medical Bill Settlement</h4>
                                <p>Once an agreement has been reached with the provider, a settlement offer will be presented to you for approval. We can typically settle your bill within 3-7 business days.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center enrollment-deadline">
                        <h4>Enrollment Deadline</h4>
                        <p>18th of the month prior to effective.</p>
                    </div>

                    <div class="col-12 what-separates">
                        <div class="row">
                            <div class="col-md-4">
                                <h4>What separates us from the rest?</h4>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5>Experience</h5>
                                        <p>Our team of negotiators have years of experience negotiating settlements with healthcare providers. Our negotiators are code certified and understand how to communicate with your physician to obtain a reasonable reimbursement.</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5>Flexibility</h5>
                                        <p>Every negotiation is different, so we are able to work out a settlement that fits all budgets whether it be one lump sum payment or a fixed monthly payment over 6 – 24 months.</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5>Our Process</h5>
                                        <p>Every bill is analyzed using our proprietary bill review software to compare what your provider is charging you to several benchmarks. These benchmarks include Medicare reimbursement, billing code edits, Usual and Customary reimbursements as well as the average of what providers are charging within a specific geographic region for a specific treatment or service.</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5>Risk Free</h5>
                                        <p>There is no risk to you to allow us to try to negotiate your bill. If there is a situation where we are unable to successfully negotiate your bill, then there is no charge to you.</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5>Timeliness</h5>
                                        <p>We can typically settle your bill within 3-7 business days, contingent on the responsiveness of your provider.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <p>So if you are ready to START SAVING NOW, then submit your claim today.</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <span class="line-top-wrap"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsumerMedical",
};
</script>