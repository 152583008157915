<template>
  <footer class="footer" id="footer">
    <div class="container footer-top">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-3 footer-col fot-about">
          <img
              src="../assets/images/elevate-logo-white.png"
              alt="elevate logo white"
          />
          <p>
          </p>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 footer-col fot-contact">
          <h5>Contact</h5>
          <ul>
            <li><i class="far fa-clock"></i> Mon - Fri 9am to 5pm</li>
            <li><i class="fas fa-envelope"></i> <a href="mailto:info@elevatetowellness.org"> info@elevatetowellness.org</a></li>
          </ul>
        </div>
        <div class="col-md-0 col-lg-1 footer-col fot-space"></div>

        <div class="col-sm-6 col-md-6 col-lg-2 footer-col fot-quick-links">
          <h5>Quick Links</h5>
          <b-navbar-nav>
            <!--            <b-nav-item to="/" href="/">Home</b-nav-item>-->
            <b-nav-item :href="grp_reg" target="_blank">Group Registration</b-nav-item>
            <b-nav-item @click="showEnrollmentModal">Group Enrollment</b-nav-item>
            <b-nav-item @click="showModal" >Member Enrollment</b-nav-item>
            <hr />
            <b-nav-item href="/about">About Us</b-nav-item>
            <b-nav-item href="/memberbenefits">Member Benefits</b-nav-item>
            <!-- <b-nav-item href="https://enroll.purenroll.com/">Benefit Store</b-nav-item> -->
            <b-nav-item href="/contact">Contact Us</b-nav-item>

          </b-navbar-nav>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 footer-col fot-get-brochure">
          <h5>Get Brochure</h5>
          <button class="btn secondary-btn"><a href="https://elevatetowellness.org/files/elevate-wellness-brochure-2017.pdf" target="_blank">Member Benefits</a></button>
        </div>
      </div>
    </div>

    <div class="container-fluid footer-bottom">
      <div class="row">
        <div class="col-12 text-center">
          <p>Copyright (C) {{currentYear}}, All Rights Reserved | ELEVATE WELLNESS</p>
        </div>
      </div>
    </div>
    <div>
      <elevate-wellness-member-ship :modeldailog="modeldailog"
                                    :modal_plan_name="modal_plan_name"
                                    :modal_plan_desc="modal_plan_desc" />
    </div>
    <div>
      <employee-enrollment  :enrollmentShow="enrollmentShow"
                             @renderLoader="renderLoader"/>
    </div>
    <div>
      <loader-global v-if="showLoader" />
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import ElevateWellnessMemberShip from "@/components/Modal/ElevateWellnessMemberShip.vue";
import EmployeeEnrollment from "@/components/Modal/EmployeeEnrollment.vue";
import LoaderGlobal from "@/components/LoaderGlobal.vue";

export default {
  name: "Footer",
  components: {
    ElevateWellnessMemberShip,
    EmployeeEnrollment,
    LoaderGlobal
  },
  data () {
    return {
      grp_reg: process.env.VUE_APP_GRP_REG,
      modeldailog: false,
      modal_plan_name: '',
      modal_plan_desc: '',
      enrollmentShow: false,
      showLoader: false
    }
  },
  computed: {
    currentYear () {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear
    }
  },
  methods: {
    showModal() {
      let vm = this;
      vm.showLoader = true;
      let url = "https://api.purenroll.com/api/v1/get-plan-detail/eyJpdiI6IkVWWEdwSExJd2hwblc4ZG9WTkhrL2c9PSIsInZhbHVlIjoiTEZrNWZnSXp6cTNUTkM0ZWVyTjF3Zz09IiwibWFjIjoiMjRmYzRiMDgyZmE5YTNlMDhkODU3NGFiOGMzNDc1NGYwMTljMTRiNmZhNzdiZjM2OTVkOWRlYTE0NDdmMzJkMSJ9";
      axios.get(url, { 'headers': { 'Authorization': 'Basic 9784d4e04561993389d077b7aaa1ae47f80ed0d52e81dd26c0eb6f121153990a' } })
          .then(function (response) {
            vm.modal_plan_name = response.data.data.plan_name;
            vm.modal_plan_desc = response.data.data.plan_desc.body_text;
            vm.modeldailog = true;
            vm.showLoader = false
          })
          .catch(function(response) {
            console.log(response);
            vm.showLoader = false
          });
    },
    showEnrollmentModal () {
      let app = this
      app.modeldailog = false
      app.$bvModal.show('employee-enrollment-pop')
    },
    renderLoader (value) {
      let app = this
      app.showLoader = value
    }
  }
};
</script>
<style>
</style>