<template>
  <div class="hero">
    <div class="container">
      <div class="row">
        <div class="col-md-5 hero-text">
          <h1>
            Dedicated to <br /><span class="p-color">Health and Wellness</span>
          </h1>
          <p>
            Elevate Wellness Association is a national organization
            <span class="p-color">dedicated to the health and wellness</span>
            of employees at small and midsize companies.
          </p>
          <div class="hero-cta">
            <button class="btn primary-btn" @click='readFile()'>Learn More</button>
            <button class="btn secondary-btn"><a href="/contact">Contact Us</a></button>
            <button class="btn join-btn" @click='showModal()'>Join Today</button>
          </div>
        </div>
        <div class="col-md-7 hero-video">
          <div class="video-div text-center">
            <video width="320" height="240" controls poster="../../assets/images/banner-thumbnail.png">
                <source src="../../assets/video/Elevate-Wellness-Video.mp4" type="video/mp4">
            </video>
            <img
              class="video-shadow"
              src="../../assets/images/video-shadow.png"
              alt="elevate wellness hero image.png"
            />
          </div>
        </div>
        <b-modal id="modal-pd" v-model="modeldailog" size="xl" title="BootstrapVue" centered>
          <div class="popUp-planDetail">
             <b-button class="close-btn" variant="close-btn" @click="modeldailog=false">
                  <i class="fas fa-times"></i>
              </b-button>
            <h2 id="modal-plan-name">Elevate Wellness Membership</h2>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Bronze Membership</h3>
                  <span class="price">$50.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjMybzVYbVAvcERxdUFWZE93bGlmcmc9PSIsInZhbHVlIjoiRWwwM0o0Y2lxYWVFWkNZeEx6QWVKZz09IiwibWFjIjoiOTk4NDJkZTg4Y2NlYjU5YjRlZmMwMjY0NmFlOTEwMTg1NWIwMjUzMWExMjU2MDM4YWFiM2UyYjg4MjRhN2FlNyJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Silver Membership</h3>
                  <span class="price">$100.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6Ijc2T1NFUkdGSzFGekovUGVVenp1Nnc9PSIsInZhbHVlIjoiMnJwUEc3NEljdzB5czVMVEpFUXNiZz09IiwibWFjIjoiYmI3NGQ3ZmM2ZDYxNDM3NDEwZTNjZDc4MzFkNWExODhhOTk2ZWUxMjFkMDg5OTNjYmQwZGEyZTFhZmQ0ZDk4MSJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Gold Membership</h3>
                  <span class="price">$150.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjdicDNpNWJZZ0xlRE5sWmR4azBZQmc9PSIsInZhbHVlIjoiWTlBNkdBdkJJeUxmeGdaRk5xZjhBdz09IiwibWFjIjoiYTJhMDBmMjE0NWEwMDdjMjRkYzdmZTRkOTc2MmE4OTRjZDBiYTg2MGVlNGNkNTM0NGRmZDc1MWM1OGQ0ODAzOSJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>
            </div>
            <h2>Plan Description</h2>
            <div v-html="modal_plan_desc"></div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data: function () {
    return {
        modeldailog: false,
        modal_plan_name: '',
        modal_plan_desc: '',
        variants: ['close-btn']
    }
  },
  name: "Banner",
  methods: {
    readFile() {
      var pdf = 'files/elevate-wellness-brochure-2017.pdf';
      window.open(pdf,'_blank');
    },
    showModal() {
      // let url = process.env.VUE_APP_CORENROLL_SYSTEM  + "encrypt-info?data=886";
      let vm = this;
      // let url = "https://api.purenroll.com/api/v1/encrypt-info?data=886";
      // axios.post(url, { 'headers': { 'Authorization': 'Basic 9784d4e04561993389d077b7aaa1ae47f80ed0d52e81dd26c0eb6f121153990a' } })
      // .then(function (resp) {
      //   let pid = resp.data;
        let url = "https://api.purenroll.com/api/v1/get-plan-detail/eyJpdiI6IkVWWEdwSExJd2hwblc4ZG9WTkhrL2c9PSIsInZhbHVlIjoiTEZrNWZnSXp6cTNUTkM0ZWVyTjF3Zz09IiwibWFjIjoiMjRmYzRiMDgyZmE5YTNlMDhkODU3NGFiOGMzNDc1NGYwMTljMTRiNmZhNzdiZjM2OTVkOWRlYTE0NDdmMzJkMSJ9";
        axios.get(url, { 'headers': { 'Authorization': 'Basic 9784d4e04561993389d077b7aaa1ae47f80ed0d52e81dd26c0eb6f121153990a' } })
        .then(function (response) {
          vm.modal_plan_name = response.data.data.plan_name;
          vm.modal_plan_desc = response.data.data.plan_desc.body_text;
          vm.modeldailog = true;
        })
        .catch(function(response) {
          console.log(response);
        });
      // })
      // .catch(function (resp) {
      //   console.log(resp);
      // });
    },
/*    benefitlink(planid, pricing_id) {
      // let vm = this;
      let url = process.env.VUE_APP_CORENROLL_SYSTEM  + "link-benefit-store?plan_id=" + planid + "&plan_pricing_id=" + pricing_id;
      axios.post(url)
      .then(function (resp) {
        let enroll_id = resp.data.data.enrollment_id;
        window.location.href = process.env.VUE_APP_ENROLL_SYSTEM + 'website?group=elevate&id=' + enroll_id;
      })
      .catch(function (resp) {
        console.log(resp);
      });
    },*/
    benefitLinkDirect (planid) {
      window.location.href = `${process.env.VUE_APP_ENROLL_SYSTEM}elevate/${planid}`
    }
  },
};
</script>
