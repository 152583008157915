<template>
<div>
  <b-modal id="modal-pd" v-model="modeldailog" size="xl" title="BootstrapVue" centered>
    <div class="popUp-planDetail">
      <b-button class="close-btn" variant="close-btn" @click="modeldailog=false">
        <i class="fas fa-times"></i>
      </b-button>
      <h2 id="modal-plan-name">Elevate Wellness Membership</h2>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
          <div class="planGrid text-center">
            <h3>Elevate Wellness Bronze Membership</h3>
            <span class="price">$50.00</span>
            <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjMybzVYbVAvcERxdUFWZE93bGlmcmc9PSIsInZhbHVlIjoiRWwwM0o0Y2lxYWVFWkNZeEx6QWVKZz09IiwibWFjIjoiOTk4NDJkZTg4Y2NlYjU5YjRlZmMwMjY0NmFlOTEwMTg1NWIwMjUzMWExMjU2MDM4YWFiM2UyYjg4MjRhN2FlNyJ9')">Select Membership<br>and<br>Begin Enrollment</a>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
          <div class="planGrid text-center">
            <h3>Elevate Wellness Silver Membership</h3>
            <span class="price">$100.00</span>
            <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6Ijc2T1NFUkdGSzFGekovUGVVenp1Nnc9PSIsInZhbHVlIjoiMnJwUEc3NEljdzB5czVMVEpFUXNiZz09IiwibWFjIjoiYmI3NGQ3ZmM2ZDYxNDM3NDEwZTNjZDc4MzFkNWExODhhOTk2ZWUxMjFkMDg5OTNjYmQwZGEyZTFhZmQ0ZDk4MSJ9')">Select Membership<br>and<br>Begin Enrollment</a>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
          <div class="planGrid text-center">
            <h3>Elevate Wellness Gold Membership</h3>
            <span class="price">$150.00</span>
            <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjdicDNpNWJZZ0xlRE5sWmR4azBZQmc9PSIsInZhbHVlIjoiWTlBNkdBdkJJeUxmeGdaRk5xZjhBdz09IiwibWFjIjoiYTJhMDBmMjE0NWEwMDdjMjRkYzdmZTRkOTc2MmE4OTRjZDBiYTg2MGVlNGNkNTM0NGRmZDc1MWM1OGQ0ODAzOSJ9')">Select Membership<br>and<br>Begin Enrollment</a>
          </div>
        </div>
      </div>
      <h2>Plan Description</h2>
      <div v-html="modal_plan_desc"></div>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  name: 'elevate-wellness-membership',
  props: {
    modeldailog: {
      type: Boolean
    },
    modal_plan_name: {
      type: String
    },
    modal_plan_desc: {
      type: String
    }
  },
  methods: {
    benefitLinkDirect (planid) {
      window.location.href = `${process.env.VUE_APP_ENROLL_SYSTEM}elevate/${planid}`
    }
  }
}
</script>

<style scoped>

</style>