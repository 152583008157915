var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"employee-enrollment-pop","centered":"","size":"md"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"popUp-header"},[_c('h4',[_vm._v("Group Enrollment")]),_c('b-button',{attrs:{"variant":"close-btn"},on:{"click":function($event){_vm.enrollmentShow = false}}},[_c('i',{staticClass:"fas fa-times"})])],1)]},proxy:true}]),model:{value:(_vm.enrollmentShow),callback:function ($$v) {_vm.enrollmentShow=$$v},expression:"enrollmentShow"}},[_c('b-container',{attrs:{"fluid":""}},[(!_vm.showProceedConformation)?_c('div',[_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Group Enrollment Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupEnrollmentCode),expression:"groupEnrollmentCode"}],staticClass:"custom-input mt-3",class:{
              'form-control is-invalid':
                _vm.$v.groupEnrollmentCode.$error || _vm.groupErrorFlag,
            },attrs:{"type":"text","placeholder":"Enter Group Enrollment CodeEg: ABCD1212"},domProps:{"value":(_vm.groupEnrollmentCode)},on:{"input":[function($event){if($event.target.composing)return;_vm.groupEnrollmentCode=$event.target.value},function($event){return _vm.removeGroupErrorMessage()}]}}),(
              !_vm.$v.groupEnrollmentCode.required &&
              _vm.$v.groupEnrollmentCode.$error
            )?_c('div',{staticClass:"form-group__message text-left ml-3 mt-2"},[_vm._v(" Field is required ")]):_vm._e(),(_vm.groupErrorFlag === true)?_c('div',{staticClass:"error_message mt-2"},[_vm._v(" Invalid Group Enrollment Code ")]):_vm._e()]),_c('div',{staticClass:"form-group semiText"},[_c('p',[_vm._v(" If you don't know your group enrollment code please contact your company's human resource department. ")])]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"univ-btn",on:{"click":function($event){return _vm.checkGroupEnrollmentCode()}}},[_vm._v(" Continue ")])])]):_vm._e(),(_vm.showProceedConformation)?_c('div',[_c('div',{staticClass:"text-left mb-3"},[_c('div',{staticClass:"mt-2"},[_vm._v(" The company that you will be enrolling under is:"),_c('strong',{staticStyle:{"color":"#28a745"}},[_vm._v(" "+_vm._s(_vm.groupInfo.name))])]),_c('div',{staticClass:"mt-2 group-not-employer-container",on:{"click":_vm.backToEnrollmentCode}},[_c('p',[_vm._v(" If this is NOT the your group please click here, to re-enter your group enrollment code. ")]),_c('p',[_vm._v(" If you have questions please contact your Employer for correct code. ")])])]),_c('b-row',[_c('b-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"univ-btn",on:{"click":_vm.routeToGroupEnroll}},[_vm._v(" Yes ")])])]),_c('b-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('button',{staticClass:"univ-btn",on:{"click":_vm.resetEnrollGroup}},[_vm._v("No")])])],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }