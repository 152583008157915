<template>
  <header class="header container" id="header">
    <div class="row navbar-top-wrap">
      <div class="col-md-8 col-sm-9 navbar-top-left">
        <nav class="navbar navbar-top" id="navbar-top">
          <div class="top-menu-left">
            <ul>
              <li class="nav-item active">
                <span href="#"
                  ><i class="fas fa-envelope"></i>
                  <a href="mailto:info@elevatetowellness.org"> info@elevatetowellness.org</a></span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="col-md-4 col-sm-3 navbar-top-right pe-0">
        <div class="corenrollApps-list">
<!--              <b-dropdown dropdown class="dashboard-icons">
                <template #button-content>
                  <div  class="aaplistIcon">
                    <i class="fas fa-user"> </i>
                        Login/Register
                  </div>
                </template>
                <b-dropdown-item class="dropdown-icon-item" href="javascript:void(0)">
                    <div class="row">
                      <h3 class="mt-2">Dashboard Login</h3>
                      <a :href="member_login" class="col-4 dashIcon-block" target="_blank">
                        <img src="../assets/images/Dashboard (Member).svg" alt="" />
                        <span class="d-block text-center">Member login</span>
                      </a>
                      <a :href="rep_login" class="col-4 dashIcon-block" target="_blank">
                        <img src="../assets/images/Dashboard (REP).svg" alt=""/>
                        <span class="d-block text-center">Rep Login</span>
                      </a>
                      <a :href="grp_login" class="col-4 dashIcon-block" target="_blank">
                        <img src="../assets/images/Dashboard (group).svg" alt="" />
                        <span class="d-block text-center">Group Login</span>
                      </a>
                      <hr/>
                      <h3>Registrations</h3>
                      <a
                        :href="rep_reg" class="col-4 dashIcon-block" target="_blank">
                        <img src="../assets/images/Da-Reg.f455e104.svg" alt="" />
                        <span class="d-block text-center">Rep Registration</span>
                      </a>
                      <a :href="member_reg" class="col-4 dashIcon-block" target="_blank">
                        <img src="../assets/images/member-registration.svg" alt="" />
                        <span class="d-block text-center">Member<br />Registration</span>
                      </a>
                      <a
                        :href="grp_reg" class="col-4 dashIcon-block" target="_blank">
                        <img src="../assets/images/group-registration.svg" alt="" />
                        <span class="d-block text-center">Group<br />Registration</span>
                      </a>
                    </div>
                </b-dropdown-item>
              </b-dropdown>-->
            </div>
      </div>
    </div>

    <div class="row navbar-main-wrap">
      <b-navbar toggleable="lg" type="light" variant="">
        <b-navbar-brand href="/">
          <a class="navbar-brand" ><img src="../assets/images/elevate-wellness-logo.png" alt="Elevate Wellness Logo"/></a></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse collapsed"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="/">Home</b-nav-item>
            <b-nav-item href="/about">About Us</b-nav-item>
            <b-nav-item href="/memberbenefits">Member Benefits</b-nav-item>
<!--            <b-nav-item @click="showModal()">Benefit Store</b-nav-item>-->
            <b-nav-item class="nav-contact" href="/contact">Contact Us</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <b-modal id="modal-pd" v-model="modeldailog" size="xl" title="BootstrapVue" centered>
          <div class="popUp-planDetail">
             <b-button class="close-btn" variant="close-btn" @click="modeldailog=false">
                  <i class="fas fa-times"></i>
              </b-button>
            <h2 id="modal-plan-name ">Elevate Wellness Membership</h2>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Bronze Membership</h3>
                  <span class="price">$5.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6Ino1VDBQaWQyYWt6akRvRDBiaWUxSFE9PSIsInZhbHVlIjoiWG9qWmVTb0s4aXJOOERCUGxIT2hMdz09IiwibWFjIjoiOTM3N2EwMzdkMTU1OWE4NWQ2ODI5MTdlNGJmYTZkODJlZmJjMmNmN2M0YzQ4NTAzMWFhNDY0MWEyMjQ2MWNkZiJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Silver Membership</h3>
                  <span class="price">$12.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjAya0NjRnJ0a0ErRjdFajM5WDE1ZkE9PSIsInZhbHVlIjoiRDQzTEt2cE5qV3F4cytkTk9WVzRCQT09IiwibWFjIjoiNjZkMDMwZTZjYTZiOTBhYzcyYTc2NGJlYWQ3ZTIxMTU5MmY4NGQ5NjE4ZGM3MTJmZGU0NjVjZTljMTQxNWU1ZiJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Gold Membership</h3>
                  <span class="price">$16.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6InI4RkVqVnVxZHFHdVNFSWhkT0t6WkE9PSIsInZhbHVlIjoiVGNaY2Q4YUo5L3NhdjY0N3lJTUlhZz09IiwibWFjIjoiZGNkOGQ3YmU2M2I2ZDE4Nzk1N2I4ZTFmYTBhY2ZkMWY1YmE5OGEzNDIyOTU2MDBiMWZmZjAxYzQ3ZWRmYTE4ZiJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>
            </div>
            <h2>Plan Description</h2>
            <div v-html="modal_plan_desc"></div>
          </div>
        </b-modal>
  </header>

</template>

<script>
import axios from 'axios';

export default {
    data: function () {
        return {
            modeldailog: false,
            modal_plan_name: "",
            modal_plan_desc: "",
            variants: ["close-btn"],
            member_reg: process.env.VUE_APP_MEMBER_REG,
            rep_reg: process.env.VUE_APP_REP_REG,
            grp_reg: process.env.VUE_APP_GRP_REG,
            grp_login: process.env.VUE_APP_GROUP_LOGIN,
            rep_login: process.env.VUE_APP_REP_LOGIN,
            member_login: process.env.VUE_APP_MEMBER_LOGIN
        };
    },
    name: "Header",
    methods: {

        showModal() {

            let vm = this;

            let url = "https://api.purenroll.com/api/v1/get-plan-detail/eyJpdiI6IkVWWEdwSExJd2hwblc4ZG9WTkhrL2c9PSIsInZhbHVlIjoiTEZrNWZnSXp6cTNUTkM0ZWVyTjF3Zz09IiwibWFjIjoiMjRmYzRiMDgyZmE5YTNlMDhkODU3NGFiOGMzNDc1NGYwMTljMTRiNmZhNzdiZjM2OTVkOWRlYTE0NDdmMzJkMSJ9";
            axios.get(url, { "headers": { "Authorization": "Basic 9784d4e04561993389d077b7aaa1ae47f80ed0d52e81dd26c0eb6f121153990a" } })
                .then(function (response) {
                vm.modal_plan_name = response.data.data.plan_name;
                vm.modal_plan_desc = response.data.data.plan_desc.body_text;
                vm.modeldailog = true;
            })
                .catch(function (response) {
                console.log(response);
            });

        },
       /* benefitlink(planid, pricing_id) {
           console.log('11')
            let url = process.env.VUE_APP_CORENROLL_SYSTEM + "link-benefit-store?plan_id=" + planid + "&plan_pricing_id=" + pricing_id;
            axios.post(url)
                .then(function (resp) {
                let enroll_id = resp.data.data.enrollment_id;
                window.location.href = process.env.VUE_APP_ENROLL_SYSTEM + "website?group=elevate&id=" + enroll_id;
            })
                .catch(function (resp) {
                console.log(resp);
            });
        },*/
      benefitLinkDirect (planid) {
        window.location.href = `${process.env.VUE_APP_ENROLL_SYSTEM}elevate/${planid}`
      }
    },
};
</script>
<style src="@/assets/css/icon.css"></style>
