<template>
  <div>
    <b-modal
        id="employee-enrollment-pop"
        centered
        size="md"
        v-model="enrollmentShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4>Group Enrollment</h4>
          <b-button variant="close-btn" @click="enrollmentShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showProceedConformation">
          <div class="form-group mb-3">
            <label>Group Enrollment Code</label>
            <input
                type="text"
                v-model="groupEnrollmentCode"
                @input="removeGroupErrorMessage()"
                :class="{
                'form-control is-invalid':
                  $v.groupEnrollmentCode.$error || groupErrorFlag,
              }"
                class="custom-input mt-3"
                placeholder="Enter Group Enrollment CodeEg: ABCD1212"
            />
            <div
                class="form-group__message text-left ml-3 mt-2"
                v-if="
                !$v.groupEnrollmentCode.required &&
                $v.groupEnrollmentCode.$error
              "
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="groupErrorFlag === true">
              Invalid Group Enrollment Code
            </div>
          </div>
          <div class="form-group semiText">
            <p>
              If you don't know your group enrollment code please contact your
              company's human resource department.
            </p>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkGroupEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showProceedConformation">
          <div class="text-left mb-3">
            <div class="mt-2">
              The company that you will be enrolling under is:<strong
                style="color: #28a745"
            >
              {{ groupInfo.name }}</strong
            >
            </div>
            <div
                class="mt-2 group-not-employer-container"
                @click="backToEnrollmentCode"
            >
              <p>
                If this is NOT the your group please click here, to re-enter
                your group enrollment code.
              </p>
              <p>
                If you have questions please contact your Employer for correct
                code.
              </p>
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToGroupEnroll">
                  Yes
                </button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetEnrollGroup">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import { required } from "vuelidate/lib/validators";
export default {
  name: 'employee-enrollment',
  data () {
    return {
      enrollmentShow: false,
      showProceedConformation: false,
      groupInfo: {
        name: "",
      },
      groupEnrollmentCode: null,
      groupRouteName: null,
      groupErrorFlag: false,
    }
  },
  validations: {
    groupEnrollmentCode: {
      required
    }
  },
  methods: {
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        app.$emit('renderLoader', true)
        axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
        axios
            .get(process.env.VUE_APP_API_BASE + "/validate-group", {
              params: {
                group_code: app.groupEnrollmentCode,
              },
            })
            .then(function (response) {
              if (response.data.status == "success") {
                app.groupInfo.name = response.data.data.name;
                app.showProceedConformation = true;
              }
              if (response.status == 204) {
                app.groupErrorFlag = true;
                app.showProceedConformation = false;
              }
              app.$emit('renderLoader', false)
            })
            .catch(function (error) {
              console.log(error);
              app.$emit('renderLoader', false)
            });
      }
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
    },
    routeToGroupEnroll() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios
          .get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              let responseData = response.data.data;
              app.groupRouteName = responseData.landing_url;
              app.enrollmentShow = false;
              app.benefitStoreLinkRedirection()
              app.resetEnrollGroup()
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    benefitStoreLinkRedirection () {
      let app = this
      let url = `${process.env.VUE_APP_ENROLL_SYSTEM}elevate-group/${app.groupRouteName}/elevate-groups-redirection`;
      window.open(url, '_blank');
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    }
  }
}
</script>
<style>
@import "../../assets/css/empolyeeEnrollment.css";
</style>