import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Vue from 'vue'

import router from './router'


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuelidate from 'vuelidate'

import App from './App.vue'

Vue.use(VueAxios, axios)
Vue.use(Vuelidate)

Vue.prototype.$axios = axios;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
