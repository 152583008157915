<template>
  <div class="ab-details elevate-wellness-shopping">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>Elevate Wellness - Shopping & Benefits</h2>
            <p>Watch Our Video for Everything You Need to Know.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="subpage-body box-shadow">
            <div class="row">
              <div class="col-12">
                <div class="ab-details-heading text-center">
                  <h5>Watch Our Video for Everything You Need to Know.</h5>
                  <video poster="https://corenroll.com/img/shop-vid-poster.PNG" class="css-14ogxpa" playsinline="" preload="metadata" muted="false" src="blob:https://embed.videodelivery.net/d517bf87-a633-4fac-9ce7-05a5a5ebaf29"></video>
                </div>
              </div>
            </div>
            <div class="row ab-details-body">
              <div class="col-12 text-center love-program">
                <h4>YOU WILL LOVE THE PROGRAM!</h4>
              </div>
              <div class="col-12 easy-steps">
                <h3>
                  The majority of individuals are not receiving cash back from
                  their online purchases, but now with Elevate Wellness you can
                  in 3 easy steps:
                </h3>
                <div class="row text-center">
                  <div class="col-lg-4 col-md-6 col-12">
                    <div class="steps step-one">
                      <img src="../assets/images/Step-1.jpg" alt="Step 1" />
                      <h4>STEP 1</h4>
                      <p>
                        Simply Access your
                        <a href="https://corenroll.com/myaccount.php"
                          >Elevate Wellness Member Dashboard</a
                        >, and Click on the Shopping & Benefits Logo
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-12">
                    <div class="steps step-two">
                      <img src="../assets/images/Step-2.jpg" alt="Step 2" />
                      <h4>STEP 2</h4>
                      <p>
                        Find the store you want to shop, click the “shop now”
                        button, and shop at the online store (as you normally
                        would).
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-12">
                    <div class="steps step-three">
                      <img src="../assets/images/Step-3.jpg" alt="Step 2" />
                      <h4>STEP 3</h4>
                      <p>
                        After you’ve made a purchase, a cash back percentage of
                        your purchase amount is deposited into your
                        ShoppingAndBenefits.com account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="line-top-wrap"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElevateWellness",
};
</script>

