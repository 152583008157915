import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import MemberBenefits from '../views/MemberBenefits.vue'
import DetailsRxGoldCard from '../views/DetailsRxGoldCard.vue'
import EwaLiveWell from '../views/EwaLiveWell.vue'
import ConsumerMedical from '../views/ConsumerMedical.vue'
import ElevateWellness from '../views/ElevateWellness.vue'
import EwaGroup from '../views/EwaGroup.vue'
import MemberShip from "../views/MemberShip";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/memberbenefits',
    name: 'MemberBenefits',
    component: MemberBenefits
  },
  {
    path: '/memberships',
    name: 'MemberShip',
    component: MemberShip
  },
  {
    path: '/detailsrxgoldcard',
    name: 'DetailsRxGoldCard',
    component: DetailsRxGoldCard
  },
  {
    path: '/ewalivewell',
    name: 'EwaLiveWell',
    component: EwaLiveWell
  },
  {
    path: '/consumermedical',
    name: 'ConsumerMedical',
    component: ConsumerMedical
  },
  {
    path: '/elevatewellness',
    name: 'ElevateWellness',
    component: ElevateWellness
  },
  {
    path: '/ewagroup',
    name: 'EwaGroup',
    component: EwaGroup
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
