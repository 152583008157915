<template>
    <div class="contact-info-wrap">
      <div class="container">
        <span class="line-top-wrap"></span>
        <div class="row contact-info-text">
          <div class="col-12 text-center">
            <h3>Questions? <b>info@elevatetowellness.org</b></h3>
            <p>We're standing by to help you make the best decision.</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "ContactInfo",
    
}
</script>