<template>
  <div class="home-body">
    <Banner />
    <HomeAbout />
    <UniqueApproach />
    <Worksite />
    <ContactInfo />

  </div>
</template>

<script>
import Banner from '../components/Homepage/Banner.vue'
import HomeAbout from '../components/Homepage/HomeAbout.vue'
import UniqueApproach from '../components/Homepage/UniqueApproach.vue'
import Worksite from '../components/Homepage/Worksite.vue'
import ContactInfo from '../components/Homepage/ContactInfo.vue'

export default {
  name: "Home",

  components: {
    Banner,
    HomeAbout,
    UniqueApproach,
    Worksite,
    ContactInfo,
  }
};
</script>
