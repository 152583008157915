<template>
  <div class="ab-details ewa-live-wrap">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>EWA LIVE WELL - MEMBER BENEFIT</h2>
            <p>
              Our unique approach blends physical, mental, and financial
              well-being into a comprehensive, incentivized personal well-being
              solution.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="subpage-body box-shadow">
            <div class="row">
              <div class="col-12">
                <div class="ab-details-heading">
                  <iframe
                    width="100%"
                    height="673"
                    src="https://www.youtube.com/embed/bp7gIm4ZOMA"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                  ></iframe>
                  <p>
                    Elevate Wellness Association is a national organization
                    dedicated to the health and wellness of employees at small
                    and midsize companies.
                  </p>
                </div>
                <div class="ab-details-body">
                  <div class="row elw-bfit-mobile">
                    <div class="col-md-6">
                      <img
                        src="../assets/images/bfitmobile-logo.png"
                        alt="B Fit Mobile logo"
                      />
                      <p>
                        B-Fit Mobile is an awesome health management system that
                        connects your smart devices and presents great weight
                        loss tools, events, fitness challenges and rewards to
                        motivate you!
                      </p>
                    </div>
                    <div class="col-md-6 text-center">
                      <iframe width="100%" height="200" src="https://player.vimeo.com/video/310887159" frameborder="0"></iframe>
                    </div>
                  </div>
                  <div class="row elw-our-goal">
                    <div class="col-md-3">
                      <img
                        src="../assets/images/mhc-now-logo.png"
                        alt="My Health coach logo"
                      />
                    </div>
                    <div class="col-md-9">
                      <h6>Our goal is simple:</h6>
                      <p>
                        To help each and every member of our association be as
                        healthy as they can be, physically, mentally, and
                        financially. To that end, we’re pleased to offer My
                        Health Coach Now (MHC-Now) to all our health plan
                        members. MHC-Now is an automated, self-paced personal
                        wellness tool that brings technology, incentives,
                        engagements, and education together in an easy to use
                        service included in your Elevate Wellness membership.
                        This unique approach blends physical, mental, and
                        financial well-being into a comprehensive, incentivized
                        personal well-being solution.
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="unique-approach">
                        <div class="container">
                          <div class="row unique-approach-top">
                            <div class="col-md-3 unique-approach-img">
                              <img
                                src="../assets/images/unique-approach-girl.png"
                                alt="Unique Approach Girl Image"
                              />
                            </div>
                          </div>

                          <div class="row health-section">
                            <div class="col-md-6 health-list">
                              <div class="row">
                                <div class="col-sm-2 col-12 icon-div">
                                  <img
                                    src="../assets/images/icon-physical-health.png"
                                    alt="unique approach girl image"
                                  />
                                </div>

                                <div class="col-sm-10 col-12 icon-description">
                                  <h4>Physical Health</h4>
                                  <p>
                                    MHC-Now provides personalized engagements,
                                    reminders, education, activities and
                                    incentives that help you maintain better
                                    health. Connected your wearable device,
                                    smart scale, or medical device, track your
                                    weight and activity, enroll in our “Tip of
                                    the Day”, and participate in our personal
                                    wellness challenges and online events.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 health-list">
                              <div class="row">
                                <div class="col-sm-2 col-12 icon-div">
                                  <img
                                    src="../assets/images/icon-financial-health.png"
                                    alt="unique approach girl image"
                                  />
                                </div>

                                <div class="col-sm-10 col-12 icon-description">
                                  <h4>Financial Health</h4>
                                  <p>
                                    Money matters are the number one cause for
                                    stress in our lives. By better managing and
                                    learning how to control our financial future
                                    we can get rid of a lot of the issues and
                                    concerns we have every day, and enable us to
                                    better focus on the present rather than
                                    worry about the future.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 health-list">
                              <div class="row">
                                <div class="col-sm-2 col-12 icon-div">
                                  <img
                                    src="../assets/images/icon-wellness-incentives.png"
                                    alt="unique approach girl image"
                                  />
                                </div>

                                <div class="col-sm-10 col-12 icon-description">
                                  <h4>Wellness Incentives</h4>
                                  <p>
                                    Employees can also take the 6-month Healthy
                                    Rewards Challenge. With Healthy Rewards
                                    employees “get some skin in the game” as
                                    they’ll pay $9.99 per month. Once enrolled,
                                    they’ll earn wellness points by completing
                                    various healthy activities. The challenge is
                                    to earn 1,000 points within 6 months. If
                                    they do, they’ll win over $250 in gift
                                    cards, coupons, discounts, and free products
                                    from our partners and sponsors. MHC-Now
                                    members earn wellness points for getting
                                    regular exercise, trying to maintain a
                                    healthy weight, learning about healthy
                                    lifestyles, getting their regular checkups
                                    flu shots, and for participating in MHC-Now
                                    supported events and activities.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 health-list">
                              <div class="row">
                                <div class="col-sm-2 col-12 icon-div">
                                  <img
                                    src="../assets/images/icon-mental-health.png"
                                    alt="unique approach girl image"
                                  />
                                </div>

                                <div class="col-sm-10 col-12 icon-description">
                                  <h4>Mental Health</h4>
                                  <p>
                                    Explore yoga, meditation, stress management,
                                    resiliency, and other techniques to help you
                                    better manage your mental well-being.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row elw-wellness-point">
                    <div class="col-12">
                      <h5>Wellness points have value!</h5>
                      <ul>
                        <li>
                          Each quarter members can earn a $25 gift card if they
                          earn 3,000 point, that $100 per year. In addition to
                          the card, they also receive discounts, prizes, and
                          coupons from our Connected Partners.
                        </li>
                        <li>
                          Wellness point can also be used as credit at Shop
                          MHC-Now, which offers a variety of wellness and
                          well-being related products and services, as well as
                          at the 4-Smart CBD on-line store, offering multiple
                          lines of high quality, low cost CBD products.
                        </li>
                        <li>
                          Each wellness point earned is also an “electronic
                          raffle ticket”. Each month, one or more $100 Amazon
                          gift cards will be raffled off. The more points you
                          have, the better you chance of winning.
                        </li>
                      </ul>
                      <img
                        class=""
                        src="../assets/images/mhcn-header.png"
                        alt="Step on up image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="line-top-wrap"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EwaLiveWell",
};
</script>

