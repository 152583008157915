<template>
    <div class="Worksite-wrap">
      <div class="container">
        <div class="row Worksite-top text-center">
          <h3>Worksite <span class="p-color">Wellness</span></h3>
          <p>
            Our integrated portal includes a comprehensive wellness solution
            that includes personalized engagement services, incentive program,
            support for over 60 smart apps and wearables devices, health
            education, wellness challenges and group activities.
          </p>
        </div>

        <div class="row Worksite-bottom text-center">
          <div class="col-md-4">
            <div class="circle-icon box-shadow">
              <img
                src="../../assets/images/ws-people-engagement.png"
                alt="People Engagement Icon"
              />
            </div>
            <div class="section-text">
              <h5>People Engagement</h5>
              <p>
                Benefits Optimization offers a complete and comprehensive suite
                of employee wellness tools, services, and activities that
                address personal wellness, chronic disease self-management, and
                health education. Services like MyHealthNavigator, Fit by the
                4th, and Fall in 2 Fitness help keep employees engages and on
                track.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="circle-icon box-shadow">
              <img
                src="../../assets/images/ws-intergated-incentives.png"
                alt="Intergated Incentives Icon"
              />
            </div>
            <div class="section-text">
              <h5>Intergated Incentives</h5>
              <p>
                Included in Benefits Optimization is an integrated health
                incentive that tracks employees’ health activities and allows
                them to accrued wellness points toward an employer sponsored
                reward. The program is designed to include components for your
                healthcare coverage, personal physical activities, health
                education, and participation in group events.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="circle-icon box-shadow">
              <img
                src="../../assets/images/ws-wearables.png"
                alt="Wearables & Smart Apps Icon"
              />
            </div>
            <div class="section-text">
              <h5>Wearables & Smart Apps</h5>
              <p>
                We built an integrated link to over 60 smart apps and wearable
                devices that automatically capture the data these devices
                generate and adds it to each employees health incentive account
                as well as their personal health report. We’ve also create an
                app that automatically tracks their health club attendance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "Worksite",
}
</script>