<template>
    <div class="home-about">
      <div class="container">
        <div class="row">
          <div class="about-img col-sm-7"></div>

          <div class="col-md-5 about-text">
            <h3>About <br /><span class="p-color">Elevate Wellness</span></h3>
            <p>
              ELEVATE provides health information, including fitness,
              nutritional and lifestyle choices via its website, e-newsletters
              and blogs, to help employees live healthier, happier lives. There
              are health advocates available as well to assist the members
              through billing questions and alternative treatment options that
              may not be available through their traditional healthcare
              providers.
            </p>
            <button class="btn primary-btn"><a href="/about">Read More</a></button>
          </div>

          <div class="col-md-5 about-responsive-image">
            <img
              src="../../assets/images/about-elevate-wellness.png"
              alt="About Elevate Welness for responsive"
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "HomeAbout",

}
</script>