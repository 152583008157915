<template>
  <div class="member-benefits">
    <div class="mb-banner">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Member Benefits</h1>
            <p>Dedicated to health and wellness | LIVE WELL</p>
            <div class="mb-cta">
              <a href="https://dashboard.purenroll.com/"><button class="btn primary-btn">Wellness Member Login</button></a>
              <!-- <button class="btn secondary-btn" @click="getGwebaccess(235);">Join Elevate Today!</button> -->
              <button class="btn secondary-btn" @click='showModal()'>Join Elevate Today!</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-about container">
      <div class="row">
        <div class="col-md-6">
          <h4>
            Elevate Wellness Association (”ELEVATE”) is a national organization
            dedicated to the health and wellness of employees at small and
            midsize companies.
          </h4>
        </div>
        <div class="col-md-6">
          <p>
            ELEVATE provides health information, including fitness, nutritional
            and lifestyle choices via its website, e-newsletters and blogs, to
            help employees live healthier, happier lives. There are health
            advocates available as well to assist the members through billing
            questions and alternative treatment options that may not be
            available through their traditional healthcare providers.</p>
            <p>
            Through ELEVATE, members can also access valuable lifestyle, supplemental
            and voluntary benefits that improves members’ lives.</p>
            <p>
            ELEVATE uses the power of its group buying to offer benefits that
            previously were not available to members who may be working at
            smaller organizations, and at costs that are lower than those plans
            found in the retail market space.
          </p>
        </div>
      </div>
    </div>

    <div class="mb-wellness-points container">
      <div class="row">
        <div class="col">
          <div class="point-top">
            <p>
              My Health Coach Now challenges people to earn 3,000 wellness
              points over a 6-month period. Participants earn wellness points by
              completing various healthy activities and education that are
              designed to improve their physical, mental, and financial health.
              They earn points by reporting daily physical activity, learning
              about healthy lifestyles, preparing for their financial future,
              getting an annual physical and flu shots, and for maintaining
              healthy, or improving not-so-healthy biometrics.
            </p>
          </div>
          <div class="row point-bottom">
            <div class="col-md-8">
              <p>
                When they earn 3,000 points participants are rewarded with gift
                cards, discount coupon, and prizes from our sponsors and
                partners.
              </p>
              <ul>
                <li>My Health Navigator (5 personal wellness programs)</li>
                <li>My Health Assistant (Proactive personalized reminders)</li>
                <li>Track My Progress (Activity & Weight)</li>
                <li>
                  Tip of the Day (Educational and inspirational e-messages)
                </li>
                <li>30-module health and wellness education modules</li>
                <li>Our Buddy-Up program for couples and friends</li>
                <li>Monthly webinars and events</li>
              </ul>
            </div>
            <div class="col-md-4 text-center">
              <img
                src="../assets/images/mb-wellness-points.png"
                alt="Wellness Tools photo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-bfit-mobile container">
      <div class="row">
        <div class="col-md-3 text-center">
          <img
            src="../assets/images/bfit-mobile.png"
            alt="B-Fit Mobile App image"
          />
        </div>
        <div class="col-md-9">
          <p>
            Our B-Fit Mobile smart app allows participants to connect supported
            wearable devices (e.g. Fitbit, Apple, Polar, Withings) or smart
            scale to MHC-Now to automatically track rewarded their healthy
            activities and biometrics.<br />
            B-Fit can track attendance at health clubs, yoga studios, tennis
            clubs, pools, and other health facilities to earn even more points.
            And members can use B-Fit to check-in at upcoming Elevate Wellness
            members-only events and activities.
          </p>
        </div>
      </div>
    </div>
    <div class="mb-additional">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-white">
            <h2>Additional Member Benefits</h2>
          </div>
          <div class="col-md-4 amb-card">
            <div class="card-body">
              <img
                src="../assets/images/amb-rxgold.png"
                alt="RX Gold Card image"
              />
              <h4>RX GOLD CARD</h4>
              <p>
                The Rx Gold uses a nationwide network with over 44,000 national
                chain stores (i.e. Walmart™, Kmart™, Target™, Duane Reade™,
                CVS™, etc.) and independent pharmacies.
              </p>
              <a href="/DetailsRxGoldCard"><button class="btn primary-btn">View Details</button></a>
              <span></span>
            </div>
          </div>
          <div class="col-md-4 amb-card">
            <div class="card-body">
              <img
                src="../assets/images/amb-ewa-wellness.png"
                alt="EWA Wellness plan image"
              />
              <h4>EWA WELLNESS PLAN - MEMBER BENEFIT</h4>
              <p>
                To help each and every member of our association be as healthy
                as they can be, physically, mentally, and financially.
              </p>
              <a href="/EwaLiveWell"><button class="btn primary-btn">View Details</button></a>
              <span></span>
            </div>
          </div>
          <div class="col-md-4 amb-card">
            <div class="card-body">
              <img
                src="../assets/images/Prudential_logo.png"
                alt="EWA Group term life image"
              />
              <h4>EWA GROUP TERM LIFE $7k - MEMBER BENEFIT</h4>
              <p>
                Members of EWA who are enrolled in a participating plan or plans that offers $7,000 of Term Life coverage will receive only one Term Life benefit per Membership.
              </p>
              <a href="/EwaGroup"><button class="btn primary-btn">View Details</button></a>
              <span></span>
            </div>
          </div>

          <div class="col-md-4 amb-card">
            <div class="card-body">
              <img
                src="../assets/images/amb-medical-bill.png"
                alt="Medical Bill Negotiation"
              />
              <h4>MEDICAL BILL NEGOTIATION</h4>
              <p>
                Support in negotiating and aggressively obtaining discounts on
                your unpaid medical bills. The process is simple and completely
                RISK FREE!
              </p>
              <a href="/ConsumerMedical"><button class="btn primary-btn">View Details</button></a>
              <span></span>
            </div>
          </div>
          <!-- <div class="col-md-4 amb-card">
            <div class="card-body">
              <img
                src="../assets/images/amb-living.png"
                alt="Living save image"
              />
              <h4>LIVING 2.0 SAVE</h4>
              <p>
                LIVING 2.0 Save gives you 8 different programs and a ton of ways
                to save money - and make money. You could be saving up to $1,000
                a month (for real).
              </p>
              <button class="btn primary-btn" @click="showModal()">View Details</button>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="mb-unique-approach">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-ua-top">
            <p>
              ELEVATE uses the power of its group buying to offer benefits that
              previously were not available to employees working at smaller
              companies, and at rates that are lower than those plans purchased
              on an individual basis.
            </p>
          </div>
          <div class="col-md-7 mb-ua-text">
            <h3>OUR UNIQUE APPROACH</h3>
            <p>
              To help each client maximize their ROI in human capital and
              employee benefits. We do this by building customized Virtrual
              Company Communities (VCC) that reflects each client's specific
              benefits environment, resources, and requirements. The VCC is the
              "go to place" for all employee benefits, HR, payroll, and wellness
              related services. It places single click, self-service technology
              at your employees finger tips.<br /><br />Our integrated portal
              includes a comprehensive wellness solution that includes
              personalized engagement services, incentive program, support for
              over 60 smart apps and wearables devices, health education,
              wellness challenges and group activities.
            </p>
            <p></p>
          </div>
          <div class="col-md-5 mb-ua-video">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/bp7gIm4ZOMA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
            >
            </iframe>
          </div>

          <div class="col-md-4 mb-un-bottom">
            <div class="icon-image">
              <img
                src="../assets/images/mb-ua-consulting.png"
                alt="Consulting icon"
              />
            </div>
            <h5>Consulting:</h5>
            <p>
              We provide complete project management, from design to
              implementation.
            </p>
          </div>
          <div class="col-md-4 mb-un-bottom">
            <div class="icon-image">
              <img
                src="../assets/images/mb-ua-technology.png"
                alt="Technology Icon"
              />
            </div>
            <h5>Technology</h5>
            <p>
              We bring web, social, mobile, messaging, and wearable technologies
              together in a single, easy to use solution.
            </p>
          </div>
          <div class="col-md-4 mb-un-bottom">
            <div class="icon-image">
              <img
                src="../assets/images/mb-ua-management.png"
                alt="Management Icon"
              />
            </div>
            <h5>Management</h5>
            <p>
              We partner with clients and provide proactive and on going support
              and management of the complete environment.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-our-goal">
      <UniqueApproach />
    </div>

    <div class="mb-health-coach">
      <span class="line-top-wrap"></span>
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3>Member <span class="p-color">Benefits</span></h3>
          </div>
          <div class="col-lg-1 col-md-0"></div>
          <div class="col-lg-5 col-md-6 mb-ua-text">
            <img
              class="health-coach-logo"
              src="../assets/images/health-coach-logo.png"
              alt="Health coach logo"
            />
            <p>
              At Elevate Wellness, we understand the importance of good health,
              and strive to help our member attain it. That’s why when you join
              the Elevate Wellness Association family, starting on January 1,
              2019, you’ll have access to our My Health Coach Now (MHC-Now)
              personal wellbeing program. MHC-Now is normally $9.95 per month,
              but it’s included as part of your membership.<br /><br />
              My Health Coach Now is a comprehensive personal wellbeing solution
              that helps people be as fit as they can be physically, mentally,
              and financially. It provides the structure, tools, engagements,
              education, and incentives that many people need to achieve their
              personal wellness goals.
            </p>
          </div>
          <div class="col-lg-5 col-md-6 mb-ua-text text-center">
            <img
              class="health-coach-mobile"
              src="../assets/images/health-coach-mobile.png"
              alt="Health coach mobile image"
            />
          </div>
          <div class="col-lg-1 col-md-0"></div>
        </div>
      </div>
    </div>
    <b-modal id="modal-pd" v-model="modeldailog" size="xl" title="BootstrapVue" centered>
          <div class="popUp-planDetail">
             <b-button class="close-btn" variant="close-btn" @click="modeldailog=false">
                  <i class="fas fa-times"></i>
              </b-button>
            <h2 id="modal-plan-name ">Elevate Wellness Membership</h2>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Bronze Membership</h3>
                  <span class="price">$50.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjMybzVYbVAvcERxdUFWZE93bGlmcmc9PSIsInZhbHVlIjoiRWwwM0o0Y2lxYWVFWkNZeEx6QWVKZz09IiwibWFjIjoiOTk4NDJkZTg4Y2NlYjU5YjRlZmMwMjY0NmFlOTEwMTg1NWIwMjUzMWExMjU2MDM4YWFiM2UyYjg4MjRhN2FlNyJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Silver Membership</h3>
                  <span class="price">$100.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6Ijc2T1NFUkdGSzFGekovUGVVenp1Nnc9PSIsInZhbHVlIjoiMnJwUEc3NEljdzB5czVMVEpFUXNiZz09IiwibWFjIjoiYmI3NGQ3ZmM2ZDYxNDM3NDEwZTNjZDc4MzFkNWExODhhOTk2ZWUxMjFkMDg5OTNjYmQwZGEyZTFhZmQ0ZDk4MSJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div class="planGrid text-center">
                  <h3>Elevate Wellness Gold Membership</h3>
                  <span class="price">$150.00</span>
                  <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjdicDNpNWJZZ0xlRE5sWmR4azBZQmc9PSIsInZhbHVlIjoiWTlBNkdBdkJJeUxmeGdaRk5xZjhBdz09IiwibWFjIjoiYTJhMDBmMjE0NWEwMDdjMjRkYzdmZTRkOTc2MmE4OTRjZDBiYTg2MGVlNGNkNTM0NGRmZDc1MWM1OGQ0ODAzOSJ9')">Select Membership<br>and<br>Begin Enrollment</a>
                </div>
              </div>
            </div>
            <h2>Plan Description</h2>
            <div v-html="modal_plan_desc"></div>
          </div>
        </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import UniqueApproach from "../components/Homepage/UniqueApproach.vue";
// import { getGwebaccess } from '../functions.js';

export default {
  data: function () {
    return {
        modeldailog: false,
        modal_plan_name: '',
        modal_plan_desc: '',
        variants: ['close-btn']
    }
  },
  name: "MemberBenefits",
  components: {
    UniqueApproach,
  },
  methods: {
    // getGwebaccess,
    showModal() {
      // let url = process.env.VUE_APP_CORENROLL_SYSTEM  + "encrypt-info?data=886";
      let vm = this;
      // let url = "https://api.purenroll.com/api/v1/encrypt-info?data=886";
      // axios.post(url, { 'headers': { 'Authorization': 'Basic 9784d4e04561993389d077b7aaa1ae47f80ed0d52e81dd26c0eb6f121153990a' } })
      // .then(function (resp) {
      //   let pid = resp.data;
        let url = "https://api.purenroll.com/api/v1/get-plan-detail/eyJpdiI6IkVWWEdwSExJd2hwblc4ZG9WTkhrL2c9PSIsInZhbHVlIjoiTEZrNWZnSXp6cTNUTkM0ZWVyTjF3Zz09IiwibWFjIjoiMjRmYzRiMDgyZmE5YTNlMDhkODU3NGFiOGMzNDc1NGYwMTljMTRiNmZhNzdiZjM2OTVkOWRlYTE0NDdmMzJkMSJ9";
        axios.get(url, { 'headers': { 'Authorization': 'Basic 9784d4e04561993389d077b7aaa1ae47f80ed0d52e81dd26c0eb6f121153990a' } })
        .then(function (response) {
          vm.modal_plan_name = response.data.data.plan_name;
          vm.modal_plan_desc = response.data.data.plan_desc.body_text;
          vm.modeldailog = true;
        })
        .catch(function(response) {
          console.log(response);
        });
      // })
      // .catch(function (resp) {
      //   console.log(resp);
      // });
    },
 /*   benefitlink(planid, pricing_id) {
      // let vm = this;
      let url = process.env.VUE_APP_CORENROLL_SYSTEM  + "link-benefit-store?plan_id=" + planid + "&plan_pricing_id=" + pricing_id;
      axios.post(url)
      .then(function (resp) {
        let enroll_id = resp.data.data.enrollment_id;
        window.location.href = process.env.VUE_APP_ENROLL_SYSTEM + 'website?group=elevate&id=' + enroll_id;
      })
      .catch(function (resp) {
        console.log(resp);
      });
    }*/
    benefitLinkDirect (planid) {
      window.location.href = `${process.env.VUE_APP_ENROLL_SYSTEM}elevate/${planid}`
    }
  }
};
</script>
