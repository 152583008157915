<template>
  <div class="container">
    <div class="popUp-planDetail membership-container">
      <h2 id="modal-plan-name " class="plan-name-membership">Elevate Wellness Membership33</h2>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
          <div class="planGrid text-center">
            <h3>Elevate Wellness Bronze Membership</h3>dd
            <span class="price">$5.00</span>
            <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6Ino1VDBQaWQyYWt6akRvRDBiaWUxSFE9PSIsInZhbHVlIjoiWG9qWmVTb0s4aXJOOERCUGxIT2hMdz09IiwibWFjIjoiOTM3N2EwMzdkMTU1OWE4NWQ2ODI5MTdlNGJmYTZkODJlZmJjMmNmN2M0YzQ4NTAzMWFhNDY0MWEyMjQ2MWNkZiJ9')">Select Membership<br>and<br>Begin Enrollment</a>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
          <div class="planGrid text-center">
            <h3>Elevate Wellness Silver Membership</h3>dd
            <span class="price">$12.00</span>
            <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6IjAya0NjRnJ0a0ErRjdFajM5WDE1ZkE9PSIsInZhbHVlIjoiRDQzTEt2cE5qV3F4cytkTk9WVzRCQT09IiwibWFjIjoiNjZkMDMwZTZjYTZiOTBhYzcyYTc2NGJlYWQ3ZTIxMTU5MmY4NGQ5NjE4ZGM3MTJmZGU0NjVjZTljMTQxNWU1ZiJ9')">Select Membership<br>and<br>Begin Enrollment</a>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 mb-5">
          <div class="planGrid text-center">
            <h3>Elevate Wellness Gold Membership</h3>
            <span class="price">$16.00</span>
            <a href="#" class="planAction-btn" @click="benefitLinkDirect('eyJpdiI6InI4RkVqVnVxZHFHdVNFSWhkT0t6WkE9PSIsInZhbHVlIjoiVGNaY2Q4YUo5L3NhdjY0N3lJTUlhZz09IiwibWFjIjoiZGNkOGQ3YmU2M2I2ZDE4Nzk1N2I4ZTFmYTBhY2ZkMWY1YmE5OGEzNDIyOTU2MDBiMWZmZjAxYzQ3ZWRmYTE4ZiJ9')">Select Membership<br>and<br>Begin Enrollment</a>
          </div>
        </div>
      </div>
      <h2>Plan Description</h2>
      <plan-detail></plan-detail>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import PlanDetail from '../components/MemberShip/PlanDetail'

export default {
  name: "MemberShip",
  data () {
    return {
      modal_plan_name: '',
      modal_plan_desc: ''
    }
  },
  components: {
    PlanDetail
  },
  methods: {
/*    benefitlink(planid, pricing_id) {
      // let vm = this;
      let url = process.env.VUE_APP_CORENROLL_SYSTEM  + "link-benefit-store?plan_id=" + planid + "&plan_pricing_id=" + pricing_id;
      axios.post(url)
          .then(function (resp) {
            let enroll_id = resp.data.data.enrollment_id;
            window.location.href = process.env.VUE_APP_ENROLL_SYSTEM + 'website?group=elevate&id=' + enroll_id;
          })
          .catch(function (resp) {
            console.log(resp);
          });
    },*/
    benefitLinkDirect (planid) {
      window.location.href = `${process.env.VUE_APP_ENROLL_SYSTEM}elevate/${planid}`
    }
  }
}
</script>

<style lang="scss">
.membership-container {
  padding: 2px 0 !important;
}
</style>
