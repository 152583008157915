<template>
  <div class="ab-details rd-gold-wrap">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>RX GOLD CARD</h2>
            <p>
              The Rx Gold uses a nationwide network with over 44,000 national
              chain stores (i.e. Walmart™, Kmart™, Target™, Duane Reade™, CVS™,
              etc.) and independent pharmacies.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="subpage-body box-shadow">
            <div class="row">
              <div class="col-12">
                <div class="ab-details-heading">
                    <div class="row">
                        <div class="col-md-2 text-center">
                            <img
                                src="../assets/images/rxcard_logo_sm.png"
                                alt="GOld Rx logo"
                            />
                        </div>
                        <div class="col-md-10">
                            <h1>The Gold Rx Discount Pharmacy Card</h1>
                            <a href="https://rxcardservice.com/rx-lookup1.php"><button class="btn primary-btn">Print Rx Card</button></a>
                            <a href="https://www.medaffordglobal.com/index.aspx"><button class="btn primary-btn">
                                RX Mail Order Price Search
                            </button></a>
                            <a href="https://rxcardservice.com/web_pdf/Rx_Card_Services_order_form-1.pdf"><button class="btn primary-btn">
                                Rx Mail Order Mail Order Form
                            </button></a>
                            <a href="https://ext.bmr-inc.com/extended/v3/pharmacy.asp?appkey=4982487922"><button class="btn primary-btn">Rx Pharmacy Search</button></a>
                            <a href="https://ext.bmr-inc.com/extended/v3/default.asp?appkey=4982487922"><button class="btn primary-btn">
                                RX Walk-In Price Search
                            </button></a>

                            <ul>
                                <li>
                                Access to thousands of Brand Drugs, Generic Drugs and
                                Over-the-Counter Medications
                                </li>
                                <li>
                                No Deductibles, No Annual Maximums, No Exclusions, No
                                Pre-Existing Conditions
                                </li>
                                <li>31-Day Supplies on most prescriptions</li>
                                <li>
                                Ability to purchase a 90-day supply of maintenance
                                medications at retail locations
                                </li>
                                <li>These benefits are discounts and not insurance</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="ab-details-body">
                  
                  <h6>The Gold Rx Pharmacy Network:</h6>
                  <p>
                    The Rx Gold uses a nationwide network with over 44,000
                    national chain stores (i.e. Walmart™, Kmart™, Target™, Duane
                    Reade™, CVS™, etc.) and independent pharmacies. To view an
                    extensive listing of prescription drugs covered under your
                    prescription discount drug card, your cost for these drugs,
                    local providers who will provide these discounts for the
                    covered drugs.
                  </p>

                  <h6>Mail Order:</h6>
                  <p>
                    Save even more with our mail order pharmacy services on both
                    Generic and Brand Name drugs.
                  </p>
                  <ul>
                    <li>Generic: Up to 30%-80% Savings.</li>
                    <li>Brand Name: Up to 30%-80% Savings.</li>
                  </ul>

                  <h6>Access to Web Program and Tools:</h6>
                  <ul>
                    <li>
                      Pharmacy Locator to help you quickly locate participating
                      pharmacies in your area
                    </li>
                    <li>
                      Medication Pricing Tools and Generic Alternative Tools
                    </li>
                    <li>Access to Frequently Asked Questions</li>
                    <li>The ability to Download Additional Membership Cards</li>
                  </ul>

                  <p>
                    <strong>Clinical Team:</strong> Members can speak directly
                    with trained clinical professionals who can help identify
                    effective and less costly prescription alternatives and work
                    with your physicians and pharmacists to select these
                    alternatives.
                  </p>

                  <p>
                    <strong
                      >*Disclosures: These benefits are discounts and not
                      insurance.</strong
                    >
                  </p>

                  <p>
                    <strong
                      >The Rx Gold Prescription Drug Savings Program is NOT
                      insurance.</strong
                    >
                    It entitles you to a discount on prescription medications at
                    time of purchase at a participating pharmacy. You will still
                    be responsible for paying the discounted cost for your
                    prescription. The amount of the discount may also vary by
                    participating pharmacy.
                  </p>
                </div>
              </div>
            </div>
            <span class="line-top-wrap"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailsRxGoldCard",
};
</script>