<template>
  <div class="contact">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>Contact us</h2>
            <p>We’d love to hear from you. Here’s how you can reach us...</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row ">
        <div class="col-12">
          <div class="subpage-body box-shadow">
            <div class="row">
              <div class="col-md-7 contact-form-wrap">
                <div class="contact-heading">
                  <h1>Get in touch</h1>
                  <span class="success">{{ successMessage }}</span>
                  <span class="errors">{{ errorMessage }}</span>
                </div>
                <form @submit="checkForm">
                  <div class="contact-form">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <label for="your-nameid"
                            >Your name <span class="required">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="your-nameid"
                            v-model="fullName"
                            placeholder="First name"
                          />
                        <span class="errors">{{ errors.nameError }}</span>
                        </div>
                        <div class="col-12 col-sm-6">
                          <label for="exampleInputEmail1"
                            >Email address <span class="required">*</span></label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            v-model="emailId"
                            placeholder="Enter email"
                          />
                          <span class="errors">{{ errors.emailError }}</span>
                        </div>
                        <div>
                          <label for="your-subject"
                            >Subject <span class="required">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="your-subject"
                            v-model="subject"
                            placeholder="Enter subject"
                          />
                          <span class="errors">{{ errors.subjectError }}</span>
                        </div>
                        <div>
                          <label for="your-message"
                            >Message <span class="required">*</span></label
                          >
                          <textarea
                            type="text"
                            class="form-control"
                            id="your-message"
                            v-model="content"
                            placeholder="Your message"
                          ></textarea>
                          <span class="errors">{{ errors.messageError }}</span>
                        </div>
                        <div style="display: flow-root;">
                          <br>
                          <vue-recaptcha @verify="markRecaptchaAsVerified" sitekey="6LeeIsIbAAAAADd9Uo6R_VCnruxwQykkZDiDjETU" :loadRecaptchaScript="true"></vue-recaptcha>
                          <span class="errors">{{ errors.pleaseTickRecaptchaMessage }}</span>
                        </div>
                        <div>
                          <button type="submit" class="btn primary-btn">
                            Send Message
                          </button>
                        </div>
                      </div>
                  </div>
                </form>

              </div>

              <div class="col-md-5 the-office-wrap">
                <div class="the-office">
                  <img src="../assets/images/postbox.png" alt="Contact us post box image" />

                  <h3>The Office</h3>
                  <p>
                    <i class="fas fa-envelope"></i> Email: 
                    <a href="mailto:info@elevatetowellness.org">info@elevatetowellness.org</a>
                  </p>
                </div>
                <div class="business-hours">
                  <h3>Business Hours</h3>
                  <ul>
                    <li><i class="far fa-clock"></i> Monday - Friday - 9am to 5pm</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span class="line-top-wrap"></span>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .errors {
      color: red !important;
      font-size: 15px !important;
      font-weight: bold;
  }  
  .success {
      color: rgb(32, 187, 39) !important;
      font-size: 15px !important;
      font-weight: bold;
  }
</style>
<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
export default {
  components: {VueRecaptcha},
  data() {
    return {
      name: "Contact",
      fullName:'',
      emailId:'',
      // toAddress:'info@elevatetowellness.org',
      toAddress:'prabhav@cloudtechservice.com',
      ccAddress:[],
      bccAddress:[],
      subject:'',
      content:'',
      errorMessage:'',
      successMessage:'',
      recaptchaVerified: false,
      errors:{
        nameError:'',
        emailError:'',
        subjectError:'',
        messageError:'',
        pleaseTickRecaptchaMessage:''
      }
    }
  },
  methods : {
    markRecaptchaAsVerified() {
      this.recaptchaVerified = true;
    },
    checkForm(e) {
      if (this.fullName && this.emailId && this.subject && this.content && this.recaptchaVerified) {
        this.sendEmail();
      }
      
      this.errors = [];

      if (!this.fullName) {
        this.errors.nameError = 'Name required.';
      }
      if (!this.emailId) {
        this.errors.emailError = 'Email required.';
      }      
      if (!this.subject) {
        this.errors.subjectError = 'Subject required.';
      }      
      if (!this.content) {
        this.errors.messageError = 'Message required.';
      }
      if (!this.recaptchaVerified) {
        this.errors.pleaseTickRecaptchaMessage = 'Please tick recaptcha.';
      }

      e.preventDefault();
    },
    sendEmail() {
      let app = this;
      let addContent = 'Name: '+app.fullName+',<br/>'+'Email: '+app.emailId+',<br/><br/> Message: <br/>'+app.content;
      let data = {
        "email_message_configuration_name": "ELEVATE_WELLNESS_CONTACT_US",
        "toAddress": [app.toAddress],
        "ccAddress": app.ccAddress,
        "bccAddress": app.bccAddress,
        "subject": app.subject + '(Elevate wellness - contact form)',
        "contentData": {"content": addContent}
      };
      let url = process.env.VUE_APP_MIX_MESSAGE_SYSTEM + "api/v1/send-email-with-content-data";
      axios.post(url, data)
      .then(function (resp) {
        console.log(resp);
        app.fullName='';
        app.emailId='';
        app.content='';
        app.subject='';
        app.successMessage = 'Message sent.';
      })
      .catch(function (resp) {
        console.log(resp);
        app.errorMessage = 'Error sending message.';
      });
    },
  }
};
</script>