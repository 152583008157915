<template>
    <div class="unique-approach">
      <div class="container">
        <div class="row unique-approach-top">
          <div class="col-md-9 unique-approach-text">
            <h3 class="for-hp">Elevate <span class="p-color">Unique Approach</span></h3>
            <h3 class="for-mbp">Our <span class="p-color">goal is simple:</span></h3>
            <p>
              To help each and every member of our association be as healthy as
              they can be, physically, mentally, and financially. To that end,
              we’re pleased to offer My Health Coach Now (MHC-Now) to all our
              health plan members. MHC-Now is an automated, self-paced personal
              wellness tool that brings technology, incentives, engagements, and
              education together in an easy to use service included in your
              Elevate Wellness membership. This unique approach blends physical,
              mental, and financial well-being into a comprehensive,
              incentivized personal well-being solution.
            </p>
          </div>
          <div class="col-md-3 unique-approach-img">
            <img
              src="../../assets/images/unique-approach-girl.png"
              alt="Unique Approach Girl Image"
            />
          </div>
        </div>

        <div class="row health-section">
          <div class="col-md-6 health-list">
            <div class="row">
              <div class="col-sm-2 col-12 icon-div">
                <img
                  src="../../assets/images/icon-physical-health.png"
                  alt="Physical Health Icon"
                />
              </div>

              <div class="col-sm-10 col-12 icon-description">
                <h4>Physical Health</h4>
                <p>
                  Part of MHC-Now is My Health Navigator, your virtual health
                  assistant that offers 3 wellness programs (Walking, Exercise,
                  and Weight Loss), activity and progress tracking, personalized
                  reminders, “Tip of the Day” e-messaging, and a 10-module
                  health education program.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 health-list">
            <div class="row">
              <div class="col-sm-2 col-12 icon-div">
                <img
                  src="../../assets/images/icon-financial-health.png"
                  alt="Financial Health Icon"
                />
              </div>

              <div class="col-sm-10 col-12 icon-description">
                <h4>Financial Health</h4>
                <p>
                  Money matters are the number one cause for stress in our
                  lives. By better managing and learning how to control our
                  financial future we can get rid of a lot of the issues and
                  concerns we have every day, and enable us to better focus on
                  the present rather than worry about the future.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 health-list">
            <div class="row">
              <div class="col-sm-2 col-12 icon-div">
                <img
                  src="../../assets/images/icon-wellness-incentives.png"
                  alt="Wellness Incentives icon"
                />
              </div>

              <div class="col-sm-10 col-12 icon-description">
                <h4>Wellness Incentives</h4>
                <p>
                  Employees can also take the 6-month Healthy Rewards Challenge.
                  With Healthy Rewards employees “get some skin in the game” as
                  they’ll pay $9.99 per month. Once enrolled, they’ll earn
                  wellness points by completing various healthy activities. The
                  challenge is to earn 1,000 points within 6 months. If they do,
                  they’ll win over $250 in gift cards, coupons, discounts, and
                  free products from our partners and sponsors.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 health-list">
            <div class="row">
              <div class="col-sm-2 col-12 icon-div">
                <img
                  src="../../assets/images/icon-mental-health.png"
                  alt="Mental Health Icon"
                />
              </div>

              <div class="col-sm-10 col-12 icon-description">
                <h4>Mental Health</h4>
                <p>
                  Explore yoga, meditation, stress management, resiliency, and
                  other techniques to help you better manage your mental
                  well-being.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "UniqueApproach",
}
</script>